import {useField} from 'remix-validated-form';

import {useTranslations} from '@/hooks/useTranslations';
import {
  default as SharedBaseInput,
  type BaseInputProps,
} from '@/components/shared/BaseInput/BaseInput';

export function BaseInput({id, ...inputProps}: BaseInputProps) {
  const {t} = useTranslations();
  const {error, getInputProps} = useField(id);

  let errorMessage;
  if (error) {
    errorMessage = t(error);
  }

  return (
    <SharedBaseInput
      {...getInputProps({id: id, ...inputProps})}
      error={errorMessage}
    />
  );
}
